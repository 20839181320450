import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Loading from './Loading'

import { getCoreEvaluations, recordCoreEvaluation } from '../actions'

import Button from '@material-ui/core/Button'

import '../styles/coreEvaluation.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient,
  }
}

const mapDispatchToProps = {
  getCoreEvaluations,
  recordCoreEvaluation,
}

let timer

class CoreEvaluation extends React.Component {
  state = {
    isStarted: false,
    isPaused: false,
    currentQuadrant: 0,
    isLoading: false,
    savedEvaluation: false
  }

  componentDidMount () {
    let quadrants = []
    // @todo use .map
    this.props.directions.forEach((direction) => {
      quadrants.push({
        direction: direction.toLowerCase(),
        time: 0,
        isStarted: false,
        complete: false,
      })
    })

    this.setState({ quadrants: quadrants })
  }

  add = () => {
    let quadrants = this.state.quadrants

    // If we're still under the limit of 2 minutes
    if (quadrants[this.state.currentQuadrant].time < 120) {
      // Add one second to the current quadrant and update the state
      quadrants[this.state.currentQuadrant].time += 1
      this.setState({ quadrants: quadrants })
      this.timer()
    }
    else {
      this.completeQuadrant()
    }
  }

  startEval () {
    let quadrants = this.state.quadrants
    quadrants[0].isStarted = true
    this.setState({ quadrants: quadrants, isStarted: true })
    this.timer()
  }

  timer () {
    timer = setTimeout(this.add, 1000)
  }

  completeQuadrant () {
    // If we have at least one more to go
    if (this.state.currentQuadrant < 3) {
      // Complete this one and set the current quadrant to the next one
      let quadrants = this.state.quadrants
      quadrants[this.state.currentQuadrant].complete = true
      this.setState({ quadrants: quadrants, currentQuadrant: this.state.currentQuadrant + 1 })
    }
    else {
      this.saveEvaluation()
    }

    // Pause the timer until they start the next one
    clearTimeout(timer)
  }

  nextQuadrant () {
    // Start the timer again
    this.timer()

    // Indicate that we've started the next quadrant
    if (this.state.currentQuadrant <= 3) {
      let quadrants = this.state.quadrants
      quadrants[this.state.currentQuadrant].isStarted = true
      this.setState({ quadrants: quadrants })
    }
  }

  async saveEvaluation () {
    this.setState({ isLoading: true })
    let evaluation = {
      patient: this.props.currentPatient._id,
      angle: Number(this.props.evalAngle),
      date: new Date(),
      location: this.props.currentLocation,
      group: this.props.currentPatient.group,
      user: this.props.currentUser._id,
      durations: {
        [this.state.quadrants[0].direction]: this.state.quadrants[0].time,
        [this.state.quadrants[1].direction]: this.state.quadrants[1].time,
        [this.state.quadrants[2].direction]: this.state.quadrants[2].time,
        [this.state.quadrants[3].direction]: this.state.quadrants[3].time
      },
    }

    let newEvaluation = await this.props.recordCoreEvaluation(evaluation)
    await this.props.getCoreEvaluations(this.props.currentPatient._id)
    this.setState({ isLoading: false, savedEvaluation: newEvaluation })
  }

  pause = () => {
    if (this.state.isPaused) {
      this.setState({ isPaused: false })
      this.timer()
    }
    else {
      this.setState({ isPaused: true })
      clearTimeout(timer)
    }
  }

  render () {
    const { theme } = this.props
    const { isLoading, quadrants, savedEvaluation, isPaused, isStarted, currentQuadrant } = this.state

    // if we're still loading, return immediately with the loading message
    if (isLoading) {
      return (
        <div>
          <Loading />
          <h3 className="loadingText">Recording Core Evaluation...</h3>
        </div>
      )
    }

    // This will get the RGB values from our theme
    // We'll set the alpha based on the length of time in the quadrant
    let rgb = theme.palette.secondary.main.substring(0, theme.palette.secondary.main.length - 2)
    let quadrantShade = []
    if (quadrants) {
      quadrantShade = [
        rgb + (quadrants[0].time / 120).toFixed(2) + ')',
        rgb + (quadrants[1].time / 120).toFixed(2) + ')',
        rgb + (quadrants[2].time / 120).toFixed(2) + ')',
        rgb + (quadrants[3].time / 120).toFixed(2) + ')',
      ]
    }

    // If we haven't finished our evaluation
    if (!savedEvaluation && quadrants) {
      return (
        <div className="coreEvaluation">
          <h2>Record Core Evaluation</h2>

          { isPaused && <p className="pauseText">Paused</p> }

          <div className="evaluationWrapper">
            <div style={{ border: '6px solid ' + theme.palette.secondary.main }} className="circle">
              <div className="angleWrapper">
                <div style={{ backgroundColor: theme.palette.secondary.main }} className="angle">{this.props.evalAngle}</div>
              </div>

              <div className="flexRow topRight">
                {
                  quadrants.map((quadrant, index) => {
                    if (index > 1) {
                      return (
                        <div key={index} className="quadrant" style={{ backgroundColor: quadrantShade[index] }}>
                          <div className="inner">
                            <header>{quadrant.direction}</header>
                            <span>{moment.utc(quadrants[index].time * 1000).format('m:ss')}</span>
                          </div>
                        </div>
                      )
                    }
                    else {
                      return false
                    }
                  })
                }
              </div>
              <div className="flexRow bottomLeft">
                <div className="quadrant" style={{ backgroundColor: quadrantShade[1] }}>
                  <div className="inner">
                    <header>{quadrants[1].direction}</header>
                    <span>{moment.utc(quadrants[1].time * 1000).format('m:ss')}</span>
                  </div>
                </div>
                <div className="quadrant" style={{ backgroundColor: quadrantShade[0] }}>
                  <div className="inner">
                    <header>{quadrants[0].direction}</header>
                    <span>{moment.utc(quadrants[0].time * 1000).format('m:ss')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="message">
            {
              quadrants[0].complete && !quadrants[currentQuadrant].isStarted &&
              <p>Please move the machine to the next quadrant position and resume the evaluation when ready.</p>
            }
          </div>

          {
            isStarted
              ? <footer className="flexRow">
                {
                  quadrants[currentQuadrant].isStarted &&
                <Button onClick={this.pause} variant="contained" style={{ border: '2px solid white', color: 'white', backgroundColor: 'rgba(0,0,0,0)' }} className='button'>
                  { isPaused ? <span>Resume</span> : <span>Pause</span> }
                </Button>
                }
                {
                  quadrants[currentQuadrant].isStarted
                    ? <Button onClick={() => this.completeQuadrant()} variant="contained" style={theme.button.primary} className="button">Finish Quadrant</Button>
                    : <Button onClick={() => this.nextQuadrant()} variant="contained" style={theme.button.primary} className="button">Next Quadrant</Button>
                }
              </footer>
              : <footer className="flexRow">
                <Button onClick={this.props.cancel} variant="contained" style={{ border: '2px solid white', color: 'white', backgroundColor: 'rgba(0,0,0,0)' }} className='button'>Cancel</Button>
                <Button onClick={() => this.startEval()} variant="contained" style={theme.button.primary} className="button">Start</Button>
              </footer>
          }

        </div>
      )
    }
    else if (quadrants) {
      return (
        <div className="evaluationComplete">
          <h2>Core Evaluation Results</h2>
          <ol>
            {
              quadrants.map((quadrant, index) => {
                return (
                  <li key={index} className="flexRow">
                    <span>{quadrant.direction}</span>
                    <span className="time">{moment.utc(quadrant.time * 1000).format('m:ss')}</span>
                  </li>
                )
              })
            }
            <li className="flexRow">
              <strong>Score</strong>
              <span>{savedEvaluation.score}</span>
            </li>
          </ol>
          <footer className="flexRow">
            <Button onClick={this.props.submitEvaluation} variant="contained" style={theme.button.secondary} className="button secondary">Go Home</Button>
            <Button onClick={this.props.afterEvaluation} variant="contained" style={theme.button.primary} className="button">Start My First Ride</Button>
          </footer>
        </div>
      )
    }
    else {
      return null
    }
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(CoreEvaluation)
