var theme = {
  palette: {
    primary: {
      main: '#19324a',
      fade: 'rgba(25,50,74,.95)',
      700: '#77ba3e'
    },
    secondary: {
      main: 'rgba(119, 186, 62, 1)',
      light: 'rgba(119, 186, 62,.15)'
    }
  },
  typography: {
    fontFamily: ['Cairo', 'sans-serif'],
    useNextVariants: true
  },
  button: {
    primary: {
      border: '2px solid #77ba3e',
      backgroundColor: '#77ba3e',
      color: 'white'
    },
    secondary: {
      border: '2px solid #77ba3e',
      backgroundColor: 'rgba(0,0,0,0)',
      color: '#77ba3e'
    },
    danger: {
      border: '2px solid #c54040',
      backgroundColor: '#c54040',
      color: 'white'
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#77ba3e'
        }
      }
    }
  }
};
export default theme;