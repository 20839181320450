import React from 'react'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import '../styles/rideConfig.scss'

import { showSnack } from 'common/actions'
import { getLocations, getCoreEvaluations, getPatientRides, recordRide, clearTargetTab } from '../actions'

import { calculateCoreScore } from 'common/functions'

import RideConfig from './RideConfig'
import CoreEvaluationLayout from './CoreEvaluationLayout'

const styles = theme => (null)

const mapStateToProps = (state) => {
  let patientGroup = state.currentUser.groups.find((group) => {
    return group._id === state.currentPatient.group
  })

  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient,
    locations: patientGroup ? patientGroup.locations : [],
    targetTab: state.targetTab
  }
}

const mapDispatchToProps = {
  showSnack,
  getLocations,
  getCoreEvaluations,
  getPatientRides,
  recordRide,
  clearTargetTab
}

class StartRide extends React.Component {
  defaultRide = { sets: [{ spins: 5, degrees: 45, direction: 'CW' }] }

  state = {
    /*
    **  rideType can be one of the following:
    **  'lastRide' -- The current user's last recorded AllCore360 ride
    **  'evaluation' -- Core Evaluation
    **  'template' -- A ride template with predefined sets, which can be modified by the user's suggested angle
    **  'custom' -- Anything that isn't one of the previous three options, defined by the user
    */
    rideType: 'evaluation',
    currentLocation: 0,
    averageAngle: 45,
    ride: this.defaultRide,
    personalBest: 0
  }

  async componentDidMount () {
    await this.props.getLocations(this.props.currentPatient.group)
    await this.props.getPatientRides(this.props.currentPatient._id)
    await this.props.getCoreEvaluations(this.props.currentPatient._id)

    // Default to the first location
    this.setState({ currentLocation: this.props.locations[0]._id })

    // If they have at least one ride, start with Last Ride
    if (this.props.currentPatient.lastRide) {
      let ride = { ...this.props.currentPatient.lastRide }
      this.setState({
        rideType: 'lastRide',
        ride: ride,
        projectedCoreScore: calculateCoreScore({ sets: ride.sets })
      })
    }
    // Otherwise set the rideType to custom and use the default ride
    else {
      this.setState({
        rideType: 'custom',
        ride: { ...this.defaultRide },
        projectedCoreScore: calculateCoreScore({ sets: this.defaultRide.sets })
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.targetTab && this.props.targetTab.length) {
        this.setState({ rideType: this.props.targetTab })
        this.props.clearTargetTab()
      }
    }

    if (prevProps.currentPatient.lastRide !== this.props.currentPatient.lastRide) {
      if (this.props.currentPatient.rides) {
        let rides = this.props.currentPatient.rides

        // Get all the core scores to see the personal best
        let coreScores = rides.map(ride => ride.coreScore).filter(Number)
        this.setState({ personalBest: Math.max.apply(null, coreScores) })
      }
    }
  }

  setAverageAngle (value) {
    let averageAngle = this.state.averageAngle
    this.setState({ averageAngle: averageAngle + value })
  }

  changeRideType = (event) => {
    if (event.target.value === 'lastRide') {
      // Set the rideConfig back to the lastRide
      let newRide = { ...this.props.currentPatient.lastRide }
      this.setState({ rideType: event.target.value, ride: newRide })
    }
    else {
      this.setState({ rideType: event.target.value })
    }
  }

  templateSwipe = index => {
    this.setState({ templateIndex: index })
  }

  changeLocation = (event) => {
    this.setState({ currentLocation: event.target.value })
  }

  handleRideConfig = (ride) => {
    this.setState({
      ride: ride,
      rideType: 'custom',
      projectedCoreScore: calculateCoreScore({ sets: ride.sets })
    })
  }

  submitRide = async () => {
    const { currentPatient } = this.props
    const { ride, currentLocation } = this.state

    this.props.goHome()

    await this.props.recordRide(currentPatient, ride.sets, currentLocation)
    await this.props.getPatientRides(currentPatient._id)

    let message = 'AllCore360˚ ride saved for ' + currentPatient.name.given + ' ' + currentPatient.name.family + '.'
    this.props.showSnack(message)
  }

  render () {
    const { isVisible, locations, currentPatient } = this.props
    const { rideType, currentLocation, personalBest, projectedCoreScore } = this.state

    return (
      <div className={classNames('page startRide', { 'shrink': !isVisible })}>

        <h2>Configure AllCore360˚ Ride</h2>
        <div className="rideDetails">
          <div className="flexRow">
            {
              locations && locations.length > 0 &&
              <div className="rideDetailSelectWrapper">
                <FormControl>
                  <InputLabel shrink htmlFor="locationSelect">Location</InputLabel>
                  <Select
                    className="rideDetailSelect"
                    id="locationSelect"
                    value={currentLocation}
                    name="Location"
                    onChange={this.changeLocation}>
                    {
                      this.props.locations && this.props.locations.map((location, index) => {
                        return (
                          <MenuItem key={location._id} value={location._id}>{location.title}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
            }
            <div className="rideDetailSelectWrapper">
              <FormControl>
                <InputLabel shrink htmlFor="rideTypeSelect">Ride Type</InputLabel>
                <Select
                  className="rideDetailSelect"
                  id="rideTypeSelect"
                  value={this.state.rideType}
                  name="Ride Type"
                  onChange={this.changeRideType}>
                  { currentPatient.lastRide && <MenuItem value={'lastRide'}>Last Ride</MenuItem> }
                  <MenuItem value={'custom'}>Custom Ride</MenuItem>
                  <MenuItem value={'evaluation'}>Core Evaluation</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {
          (rideType === 'lastRide' || rideType === 'custom') &&
          <RideConfig
            isVisible={isVisible}
            projectedCoreScore={projectedCoreScore}
            personalBest={personalBest}
            ride={this.state.ride}
            onChange={this.handleRideConfig}
            currentPatient={this.props.currentPatient}
            goHome={this.props.goHome}
            submitRide={this.submitRide}
          />
        }

        {
          rideType === 'evaluation' &&
          <CoreEvaluationLayout
            isVisible={isVisible}
            currentLocation={currentLocation}
            submit={() => this.submitEvaluation()}
            afterEvaluation={() => this.startRideAfterEvaluation()}
          />
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(StartRide)
