import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { ValidatorForm } from 'react-material-ui-form-validator'

import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CustomTableCell from './CustomTableCell'

import Portal from '@material-ui/core/Portal'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'

import Loading from './Loading'

import { getPatient, addNewPatient, getPatients } from '../actions'

import Icon from '@material-ui/core/Icon'

import '../styles/patientProfile.scss'
import compose from 'recompose/compose'
import moment from 'moment'

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    patients: state.patients
  }
}

const mapDispatchToProps = {
  getPatient,
  getPatients,
  addNewPatient
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  row: {
    transition: '.2s',
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(25,50,74,.1)',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(113,113,113,.1)',
    },
    '&:hover': {
      backgroundColor: 'rgba(113,113,113,.3)',
    },
  },
})

class PatientTable extends React.Component {
  state = {
    patients: [],
    searchQuery: '',
    newPatient: null,
    isFetched: false
  }

  async componentDidMount () {
    let component = this

    document.body.addEventListener('click', function (event) {
      if (event.target.parentElement.getAttribute('data-refresh')) {
        component.setState({ newPatient: null })
      }
    })

    await this.props.getPatients(this.props.group._id)

    let groupPatients = this.props.patients.filter((patient) => {
      return patient.group === this.props.group._id
    })

    this.setState({ patients: groupPatients, groupPatients: groupPatients, isFetched: true })
  }

  filterPatients (event) {
    window.scrollTo(0, 0)

    let value = event.currentTarget.value.toLowerCase()
    this.setState({ searchQuery: value })

    if (value.length > 0) {
      // Filter out patients whose information doesn't contain the query
      let filteredPatients = this.state.groupPatients.filter((patient) => {
        let name = patient.name.given.toLowerCase() + ' ' + patient.name.family.toLowerCase()
        return name.indexOf(value) > -1 || patient.patientID.indexOf(value) > -1
      })

      if (filteredPatients.length > 0) {
        this.setState({ patients: filteredPatients })
      }
      else {
        this.setState({ patients: [] })
      }
    }
    else {
      this.setState({ patients: this.state.groupPatients })
    }
  }

  addPatient () {
    this.setState({
      newPatient: true,
      dob: '',
      firstName: '',
      lastName: '',
      nickname: '',
      patientId: '',
      gender: 'male',
      email: '',
      phone: '',
      emailPermission: {
        rideComplete: true,
        evalComplete: true
      }
    })
  }

  handleChange (event) {
    let name = event.target.name
    let emailPermission = this.state.emailPermission
    if (name === 'patientId') {
      if (this.state.patientId.length < 9 && event.target.value.length < 9) {
        this.setState({ [name]: event.target.value })
      }
    }
    else if (name === 'rideComplete' || name === 'evalComplete') {
      emailPermission[name] = event.target.checked
      this.setState({ emailPermission: emailPermission })
    }
    else {
      this.setState({ [name]: event.target.value })
    }
  }

  async submitPatient () {
    let dob
    if (this.state.dob) {
      dob = moment(this.state.dob).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
    }
    else {
      dob = null
    }

    let patient = {
      group: this.props.group._id,
      name: {
        given: this.state.firstName,
        family: this.state.lastName
      },
      phone: this.state.phone,
      dob: dob,
      email: this.state.email,
      nickname: this.state.nickname,
      gender: this.state.gender,
      patientID: this.state.patientId,
      emailPermission: {
        rideComplete: this.state.emailPermission.rideComplete,
        evalComplete: this.state.emailPermission.evalComplete
      }
    }

    let addedPatient = await this.props.addNewPatient(patient)

    // If we didn't run into any errors
    if (addedPatient) {
      this.setState({ newPatient: null, addedPatient: addedPatient })
      // Get us over to the rider page for our newly created rider
      this.props.history.push('/user/' + addedPatient._id)
    }
  }

  render () {
    const { classes, theme } = this.props

    let height
    let currentPage
    if (this.props.page !== this.props.index) {
      height = '75vh'
    }
    else {
      currentPage = true
    }

    if (this.props.patients.length > 0 || this.state.isFetched) {
      return (
        <div className="page users" style={{ height: height }}>
          {
            !this.state.newPatient && currentPage &&
            <Portal container={document.body}>

              <div className="userSearch">
                <TextField
                  className="inputSearch"
                  fullWidth={true}
                  placeholder="Search for riders..."
                  classes={{ root: 'search' }}
                  type="text"
                  name="userSearch"
                  margin="normal"
                  variant="outlined"
                  value={this.state.searchQuery}
                  onChange={(event) => this.filterPatients(event)}
                />
                <div onClick={() => this.addPatient()} className="addUser">
                  <Icon color="secondary">person_add</Icon>
                </div>
              </div>
            </Portal>
          }
          {
            this.state.newPatient &&
            <div className="addNewPatient">
              <h2>Add New Rider</h2>

              <ValidatorForm
                className="patientProfileForm"
                onSubmit={() => {
                  this.submitPatient()
                }}
              >

                <div className="flexRow">
                  <TextField
                    validators={['required']}
                    required
                    inputProps={{
                      name: 'firstName'
                    }}
                    label="First Name"
                    classes={{ root: 'form-group' }}
                    value={this.state.firstName}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <TextField
                    type="text"
                    required
                    inputProps={{
                      name: 'lastName'
                    }}
                    label="Last Name"
                    classes={{ root: 'form-group' }}
                    value={this.state.lastName}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                </div>

                <div className="flexRow">
                  <TextField
                    type="text"
                    inputProps={{
                      name: 'nickname'
                    }}
                    label="Nickname"
                    classes={{ root: 'form-group' }}
                    value={this.state.nickname}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />

                  <FormControl className={this.state.disabled ? 'disabled form-group' : 'form-group'}>
                    <InputLabel htmlFor="gender">Gender</InputLabel>
                    <Select
                      value={this.state.gender}
                      onChange={(event) => {
                        this.handleChange(event)
                      }}
                      inputProps={{
                        id: 'gender',
                        name: 'gender'
                      }}
                    >
                      <MenuItem value={''}></MenuItem>
                      <MenuItem value={'male'}>Male</MenuItem>
                      <MenuItem value={'female'}>Female</MenuItem>
                    </Select>
                  </FormControl>

                </div>

                <div className="flexRow">
                  <TextField
                    type="date"
                    required
                    inputProps={{
                      name: 'dob'
                    }}
                    label="Date of Birth"
                    classes={{ root: 'form-group' }}
                    value={moment(this.state.dob).format('YYYY-MM-DD')}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <TextField
                    inputProps={{
                      name: 'patientId',
                    }}
                    type="number"
                    label="Rider ID"
                    classes={{ root: 'form-group' }}
                    value={this.state.patientId}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                </div>

                <div className="flexRow">
                  <TextField
                    required
                    type="email"
                    validators={['required', 'isEmail']}
                    inputProps={{
                      name: 'email'
                    }}
                    label="Email Address"
                    classes={{ root: 'form-group' }}
                    value={this.state.email}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />

                  <TextField
                    required
                    inputProps={{
                      name: 'phone',
                      type: 'tel',
                    }}
                    label="Phone"
                    classes={{ root: 'form-group' }}
                    value={this.state.phone}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                </div>

                <h2>Email Preferences</h2>

                <div className="flexRow preferences">
                  <Checkbox
                    checked={this.state.emailPermission.rideComplete}
                    name='rideComplete'
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <p>Yes, I'd like to receive an email summary after AllCore360˚ rides</p>
                </div>

                <div className="flexRow preferences">
                  <Checkbox
                    checked={this.state.emailPermission.evalComplete}
                    name='evalComplete'
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <p>Yes, I'd like to receive an email summary after Core Evaluations</p>
                </div>

                <footer className="flexRow">
                  <Button onClick={() => this.setState({ newPatient: null })} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                  <Button type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
                </footer>

              </ValidatorForm>
            </div>

          }

          {
            !this.state.newPatient &&
            <Table className="patientTable">
              <TableHead>
                <TableRow>
                  <CustomTableCell>Name</CustomTableCell>
                  <CustomTableCell>Email</CustomTableCell>
                  <CustomTableCell>Rider ID</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.patients.map((patient, index) => {
                    return (
                      <TableRow className={classes.row} key={index}>
                        <CustomTableCell> <Link to={'/user/' + patient._id}> {patient.name.given} {patient.name.family} </Link></CustomTableCell>
                        <CustomTableCell> <Link to={'/user/' + patient._id}> {patient.email} </Link></CustomTableCell>
                        <CustomTableCell> <Link to={'/user/' + patient._id}> {patient.patientID && patient.patientID} </Link></CustomTableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>

          }
          {
            !this.state.patients.length && this.state.searchQuery.length > 0 &&
            <p style={{ textAlign: 'center' }}>No riders found.</p>
          }
        </div>
      )
    }
    if (!this.state.isFetched) {
      return (
        <div className="page users empty">
          <h3 className="loadingText">Fetching Riders...</h3>
          <Loading/>
        </div>
      )
    }
    else {
      return false
    }
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientTable)
