import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import ListExpandableItemAC360 from './ListExpandableItemAC360'
import ListExpandableItemCoreEval from './ListExpandableItemCoreEval'
import ListExpandableItemAssessment from './ListExpandableItemAssessment'
import ListExpandableItemNote from './ListExpandableItemNote'
import ListExpandableItemQuestionnaire from './ListExpandableItemQuestionnaire'

import moment from 'moment'

import {
  deleteCoreEvaluation,
  getPatientRides,
  updateRide,
  updateSession,
  getCoreEvaluations,
  updateCoreEvaluation,
  updatePatientAssessment,
  deletePatientAssessment,
  getPatientAssessments
} from '../actions'

const mapStateToProps = ({ currentPatient, currentUser: { groups } }) => {
  let group = groups.find(group => group._id === currentPatient.group)
  let locations = group && group.locations

  return {
    locations
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

const mapDispatchToProps = {
  updateCoreEvaluation,
  getCoreEvaluations,
  deleteCoreEvaluation,
  updateSession,
  updateRide,
  getPatientRides,
  updatePatientAssessment,
  deletePatientAssessment,
  getPatientAssessments
}

class ListExpandable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: null,
      menu: null,
      editing: null,
      confirmDelete: null,
      data: [],
      editingItem: null
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.calculateSessionTotals()
    }
  }

  componentDidMount () {
    this.calculateSessionTotals()
  }

  calculateSessionTotals () {
    if (this.props.user) {
      if (this.props.user.roles.includes('administrator')) {
        this.setState({ admin: true })
      }
    }

    // Calculate personal best
    if (this.props.type === 'allcore360') {
      let coreScores = this.props.data.map(ride => ride.coreScore).filter(Number)

      if (coreScores) {
        this.setState({ personalBest: Math.max.apply(null, coreScores) })
      }
    }

    let data = this.props.data

    let weightedAngle = function (angle) {
      return angle.reduce(function (a, b) {
        return a + b
      }, 0)
    }

    if (this.props.type === 'allcore360') {
      data.forEach(ride => {
        let sessionSpins = 0
        let degrees = []

        // If this is a normal ride session
        let sets = ride.sets

        if (sets) {
          sets.forEach((set) => {
            // Add up the spins for this session
            sessionSpins += set.spins
          })

          sets.forEach((set) => {
            // Push our angle multiplied by the number of spins at that angle divided by total spins
            degrees.push((set.spins / sessionSpins) * set.degrees)
          })
        }

        // @todo careful populting the ride object.
        ride.totalSpins = sessionSpins
        ride.avgAngle = Math.round(weightedAngle(degrees))
      })
    }

    this.setState({ data: data })
  }

  handleChange = panel => (event, expanded) => {
    if (this.state.expanded !== panel && !this.state.menu) {
      this.setState({
        menu: null,
        expanded: expanded ? panel : false,
        editing: null,
        editingItem: null
      })
    }
    else {
      this.setState({
        expanded: false,
        menu: null,
        editing: null,
        editingItem: null
      })
    }
  };

  expandMenu = (id) => {
    if (this.state.menu !== id) {
      this.setState({
        menu: id
      })
    }
    else {
      this.setState({
        menu: null
      })
    }
  }

  confirmDelete (index) {
    this.setState({ confirmDelete: index })
  }

  deleteItem = async (item, type, bundle) => {
    let id = item._id

    switch (type) {
      case 'allcore360':

        await this.props.updateSession(id, moment(item.startTime).format('MMMM Do, YYYY'))

        let remainingSessions = this.state.data.filter((item) => {
          return item._id !== id
        })

        this.props.getPatientRides(this.props.patient._id)
        this.props.getCoreEvaluations(this.props.patient._id)

        this.setState({ confirmDelete: null, menu: null, expanded: null, editing: null, data: remainingSessions })

        break

      case 'evaluation':
        await this.props.deleteCoreEvaluation(id, moment(item.startTime).format('MMMM Do, YYYY'))

        let data = this.state.data.filter((item) => {
          return item._id !== id
        })

        this.setState({ confirmDelete: null, menu: null, expanded: null, editing: null, data: data })

        break
      case 'assessment':
        await this.props.deletePatientAssessment(id, bundle, moment(item.createdAt).format('MMMM Do, YYYY'))
        this.props.getPatientAssessments(this.props.patient._id)
        this.setState({ confirmDelete: null, menu: null, expanded: null, editing: null })
        break
      default:
        return true
    }
  }

  editItem = (item, index) => {
    // Temporarily store our edited durations in 0:00 format
    if (item.durations) {
      item.editedDurations = {
        backward: moment.utc(item.durations.backward * 1000).format('m:ss'),
        right: moment.utc(item.durations.right * 1000).format('m:ss'),
        forward: moment.utc(item.durations.forward * 1000).format('m:ss'),
        left: moment.utc(item.durations.left * 1000).format('m:ss'),
      }
    }

    this.setState({ editing: index, menu: null, editingItem: item })
  }

  editAssessment = (type, assessment) => {
    let editingItem

    if (type === 'assessment') {
      editingItem = {
        date: assessment.createdAt,
        values: [
          assessment.values['balance'],
          assessment.values['flexibility'],
          assessment.values['pain'],
          assessment.values['rangeOfMotion'],
          assessment.values['strength'],
        ]
      }
    }
    if (type === 'note') {
      editingItem = {
        date: assessment.createdAt,
        value: assessment.value
      }
    }

    this.setState({ editing: assessment._id, menu: null, editingItem: editingItem })
  }

  updateParameter = (parameter, value, index) => {
    let session = this.state.editingItem
    let targetSet = session.sets[index]

    if (parameter !== 'direction' && parameter !== 'date') {
      if (targetSet) {
        targetSet[parameter] = Number(value)
        this.setState({ editingItem: session })
      }
      // If this is a core evaluation, update the appropriate duration or angle
      else {
        if (parameter !== 'evalAngle') {
          session.editedDurations[parameter] = value.target.value
        }
        else {
          session.angle = value.target.value
        }
        this.setState({ editingItem: session })
      }
    }
    else if (parameter === 'direction') {
      targetSet['direction'] = value.target.checked ? 'CW' : 'CCW'
      this.setState({ editingItem: session })
    }
    else if (parameter === 'date') {
      session.startTime = value.target.value
      this.setState({ editingItem: session })
    }

    else if (parameter === 'date') {
      session.startTime = value.target.value
      this.setState({ editingItem: session })
    }
  }

  updateAssessment = (index, event) => {
    let assessment = this.state.editingItem

    if (this.props.type === 'assessment') {
      if (index === 'date') {
        assessment.date = event.target.value
      }
      else {
        assessment.values[index] = Number(event)
      }
      this.setState({ editingItem: assessment })
    }

    if (this.props.type === 'note') {
      if (index === 'date') {
        assessment.date = event.target.value
      }
      else {
        assessment.value = event.target.value
      }
      this.setState({ editingItem: assessment })
    }
  }

  changeLocation = (event) => {
    let editingItem = this.state.editingItem
    editingItem.location = event.target.value
    this.setState({ editingItem: editingItem })
  }

  submitItem = async (type, index) => {
    let editingItem = this.state.editingItem
    let startTime = editingItem.startTime

    switch (type) {
      case 'allcore360':
        editingItem.startTime = moment(startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')

        await this.props.updateRide(editingItem, moment(this.state.editingItem.startTime).format('MMMM Do, YYYY'))
        this.setState({ editingItem: null, menu: null, editing: null })

        await this.props.getPatientRides(this.props.patient._id)
        this.props.getCoreEvaluations(this.props.patient._id)

        break

      case 'evaluation':
        editingItem.date = moment(startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')

        this.setState({ editingItem: null, menu: null, editing: null })

        // Convert our durations back into seconds
        editingItem.durations = {
          backward: (moment.duration('0:0' + editingItem.editedDurations.backward).asSeconds()),
          forward: (moment.duration('0:0' + editingItem.editedDurations.forward).asSeconds()),
          left: (moment.duration('0:0' + editingItem.editedDurations.left).asSeconds()),
          right: (moment.duration('0:0' + editingItem.editedDurations.right).asSeconds())
        }

        await this.props.updateCoreEvaluation(editingItem, moment(editingItem.startTime).format('MMMM Do, YYYY'))
        this.props.getCoreEvaluations(this.props.patient._id)

        break
      case 'assessment':
        let targetAssessment = this.props.data.find((assessment) => {
          return assessment._id === this.state.editing
        })

        targetAssessment.values['balance'] = this.state.editingItem.values[0]
        targetAssessment.values['flexibility'] = this.state.editingItem.values[1]
        targetAssessment.values['pain'] = this.state.editingItem.values[2]
        targetAssessment.values['rangeOfMotion'] = this.state.editingItem.values[3]
        targetAssessment.values['strength'] = this.state.editingItem.values[4]
        targetAssessment.createdAt = moment(this.state.editingItem.date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')

        this.props.updatePatientAssessment(targetAssessment, 'Assessment', this.props.patient)
        this.setState({ menu: null, expanded: null, editing: null, editingItem: null })

        break
      case 'note':

        targetAssessment = this.props.data.find((assessment) => {
          return assessment._id === this.state.editing
        })

        targetAssessment.value = this.state.editingItem.value
        targetAssessment.createdAt = moment(this.state.editingItem.date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')

        this.props.updatePatientAssessment(targetAssessment, 'Note', this.props.patient)
        this.setState({ menu: null, expanded: null, editing: null, editingItem: null })

        break
      default:
        return true
    }
  }

  render () {
    const { classes, theme, locations } = this.props

    return (
      <ol className="listExpandable">
        {
          this.props.type === 'allcore360' && this.state.data && this.state.data.map((session, index) => {
            // If this is a regular AllCore360 ride
            // @todo find a better way to distinguish between rides and core evaluations
            if (session.sets && !this.props.evalOnly) {
              let sets = session.sets
              let coreScore = session.coreScore

              return (
                <ListExpandableItemAC360
                  key={session._id}
                  index={index}
                  classes={classes}
                  theme={theme}
                  locations={locations}
                  session={session}
                  sets={sets}
                  coreScore={coreScore}
                  state={this.state}
                  handleChange={this.handleChange}
                  expandMenu={this.expandMenu}
                  editItem={this.editItem}
                  submitItem={this.submitItem}
                  deleteItem={this.deleteItem}
                  updateParameter={this.updateParameter}
                  changeLocation={this.changeLocation}
                  cancelDelete={() => this.setState({ confirmDelete: false })}
                  confirmDelete={() => this.setState({ confirmDelete: index })}
                  cancelEdit={() => this.setState({ editing: null, editingItem: null })}
                />
              )
            }

            // Otherwise, if this is a Core Evaluation...
            else if (!session.sets) {
              return (
                <ListExpandableItemCoreEval
                  key={session._id}
                  index={index}
                  classes={classes}
                  theme={theme}
                  locations={locations}
                  session={session}
                  state={this.state}
                  handleChange={this.handleChange}
                  expandMenu={this.expandMenu}
                  editItem={this.editItem}
                  submitItem={this.submitItem}
                  deleteItem={this.deleteItem}
                  updateParameter={this.updateParameter}
                  changeLocation={this.changeLocation}
                  confirmDelete={() => this.setState({ confirmDelete: index })}
                  cancelEdit={() => this.setState({ editing: null, editingItem: null })}
                  cancelDelete={() => this.setState({ confirmDelete: false })}
                />
              )
            }

            return true
          })
        }

        {
          this.props.type === 'assessment' && this.props.data && this.props.data.map((assessment, index) => {
            return (
              <ListExpandableItemAssessment
                key={assessment._id}
                index={index}
                classes={classes}
                theme={theme}
                assessment={assessment}
                state={this.state}
                handleChange={this.handleChange}
                expandMenu={this.expandMenu}
                editAssessment={this.editAssessment}
                updateAssessment={this.updateAssessment}
                submitItem={this.submitItem}
                deleteItem={this.deleteItem}
                confirmDelete={() => this.setState({ confirmDelete: index })}
                cancelEdit={() => this.setState({ editing: null, editingItem: null })}
                cancelDelete={() => this.setState({ confirmDelete: false })}
              />
            )
          })
        }

        {
          this.props.type === 'note' && this.props.data && this.props.data.map((note, index) => {
            return (
              <ListExpandableItemNote
                key={note._id}
                index={index}
                classes={classes}
                theme={theme}
                note={note}
                state={this.state}
                handleChange={this.handleChange}
                expandMenu={this.expandMenu}
                editAssessment={this.editAssessment}
                updateAssessment={this.updateAssessment}
                submitItem={this.submitItem}
                deleteItem={this.deleteItem}
                confirmDelete={() => this.setState({ confirmDelete: index })}
                cancelEdit={() => this.setState({ editing: null, editingItem: null })}
                cancelDelete={() => this.setState({ confirmDelete: false })}
              />
            )
          })
        }

        {
          this.props.type === 'questionnaire' && this.props.data && this.props.data.map((questionnaire, index) => {
            return (
              <ListExpandableItemQuestionnaire
                key={questionnaire._id}
                index={index}
                classes={classes}
                theme={theme}
                result={questionnaire}
                content={this.props.content}
                state={this.state}
                handleChange={this.handleChange}
                expandMenu={this.expandMenu}
                editAssessment={this.editAssessment}
                updateAssessment={this.updateAssessment}
                submitItem={this.submitItem}
                deleteItem={this.deleteItem}
                confirmDelete={() => this.setState({ confirmDelete: index })}
                cancelEdit={() => this.setState({ editing: null, editingItem: null })}
                cancelDelete={() => this.setState({ confirmDelete: false })}
              />
            )
          })
        }

      </ol>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(ListExpandable)
