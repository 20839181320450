import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'

import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Navbar from './Navbar'
import UserProfile from './UserProfile'
import SettingsLocations from './SettingsLocations'

import { getPatients } from '../actions'

import '../styles/index.scss'
import '../styles/patients.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentUser }) => ({ currentUser })

const mapDispatchToProps = {
  getPatients
}

class SettingsLayout extends React.Component {
  state = {
    page: 0,
  }

  handleChange = (event, page) => {
    window.scrollTo(0, 0)
    this.setState({ page })
    localStorage.setItem('currentTab', JSON.stringify({ page: 'settingsLayout', tab: Number(page) }))
  }

  handleChangeIndex = index => {
    window.scrollTo(0, 0)
    this.setState({ page: index })
    localStorage.setItem('currentTab', JSON.stringify({ page: 'settingsLayout', tab: Number(index) }))
  }

  componentDidMount () {
    this.props.getPatients()
  }

  render () {
    const { theme } = this.props

    let userAgent
    if (navigator.userAgent.indexOf('Firefox') >= 0) {
      userAgent = 'firefox'
    }

    return (
      <div>
        <AppBar position="fixed" className="appBar">

          <Navbar title="Settings" />

          <Tabs
            variant="fullWidth"
            value={this.state.page}
            onChange={this.handleChange}
            classes={{ root: 'navTabs ' + userAgent, indicator: 'tabsIndicator' }}>

            <Tab disableRipple label="My Profile"/>
            <Tab disableRipple label="Locations"/>

          </Tabs>
        </AppBar>

        <SwipeableViews
          animateHeight={false}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.page}
          onChangeIndex={this.handleChangeIndex}>

          <UserProfile />
          <SettingsLocations />

        </SwipeableViews>

      </div>
    )
  }
}

SettingsLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SettingsLayout)
