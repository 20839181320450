import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Portal from '@material-ui/core/Portal'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import CoreEvaluation from './CoreEvaluation'

import '../styles/coreEvaluation.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient,
  }
}

class CoreEvaluationLayout extends React.Component {
  state = {
    directionChange: null,
    directions: ['Forward', 'Left', 'Backward', 'Right'],
    evalAngle: 'select'
  }

  changeEvalAngle = (event) => {
    this.setState({ evalAngle: event.target.value })
  }

  afterEvaluation = () => {
    let message = 'Core Evaluation saved for ' + this.props.currentPatient.name.given + ' ' + this.props.currentPatient.name.family + '.'
    this.props.showSnack(message)
    this.props.goHome()
  }

  submitEvaluation = () => {
    this.setState({ modalConfirm: false })
  }

  setDirectionOrder = (direction, order, index) => {
    let directions = this.state.directions
    let targetIndex

    if (order === 'up') {
      targetIndex = index - 1
      this.setState({ directionChange: [{ direction: direction, order: 'up' }, { direction: directions[targetIndex], order: 'down' }] })
    }
    if (order === 'down') {
      targetIndex = index + 1
      this.setState({ directionChange: [{ direction: direction, order: 'down' }, { direction: directions[targetIndex], order: 'up' }] })
    }

    setTimeout(() => {
      directions.splice(index, 1)
      directions.splice(targetIndex, 0, direction)
      this.setState({ directions: directions, directionChange: null })
    }, 400)
  }

  render () {
    const { theme, isVisible, currentLocation } = this.props
    const { directions, modalConfirm, directionChange, evalAngle } = this.state

    return (
      <div className="evaluationOverview">
        <p>Welcome to the AllCore360˚ Core Evaluation.</p>
        <p>The core evaluation score will measure core strength, endurance, and balance in four quadrants (Anterior Compartment, Posterior Compartment, Right Lateral Compartment, Left Lateral Compartment).</p>
        <p>The overall score will be a combination of the following categories: </p>

        <ul>
          <li><strong style={{ color: theme.palette.primary.main }}>Core Strength</strong>The specific angle at which the rider holds a perfect seated posture.</li>
          <li><strong style={{ color: theme.palette.primary.main }}>Endurance</strong>The amount of time the rider holds a perfect seated posture in each quadrant.</li>
          <li><strong style={{ color: theme.palette.primary.main }}>Balance</strong>The difference between anterior & posterior compartments and right/left lateral compartments.</li>
        </ul>

        <h2>Set Direction Order</h2>
        <ol>
          {
            directions.map((direction, index) => {
              let className
              if (directionChange && directionChange[0].direction === direction) {
                className = directionChange[0].order === 'up' ? 'slideUp' : 'slideDown'
              }
              else if (directionChange && directionChange[1].direction === direction) {
                className = directionChange[1].order === 'up' ? 'slideUp' : 'slideDown'
              }

              return (
                <li key={index} className={className}>
                  <span
                    onClick={() => {
                      this.setDirectionOrder(direction, 'up', index)
                    }}
                    className={classNames('arrowUp', { 'active': !directionChange })}
                    style={{ opacity: index !== 0 ? 1 : 0.25, pointerEvents: index !== 0 ? 'all' : 'none' }}
                  />
                  <header>{direction}</header>
                  <span
                    onClick={() => {
                      this.setDirectionOrder(direction, 'down', index)
                    }}
                    className={classNames('arrowDown', { 'active': !directionChange })}
                    style={{ opacity: index !== 3 ? 1 : 0.25, pointerEvents: index !== 3 ? 'all' : 'none' }}
                  />
                </li>
              )
            })
          }
        </ol>

        {
          isVisible && !modalConfirm &&
          <Portal container={document.body}>
            <footer className="startRideAction">

              <div className="angleSelectWrapper">
                <div className="flexRow">
                  <i className="icon icon-angle"/>
                  <Select
                    className="angleSelect"
                    value={evalAngle}
                    onChange={this.changeEvalAngle}>
                    <MenuItem key={0} value={'select'}>Select Angle</MenuItem>
                    <MenuItem key={1} value={80}>80˚</MenuItem>
                    <MenuItem key={2} value={75}>75˚</MenuItem>
                    <MenuItem key={3} value={60}>60˚</MenuItem>
                    <MenuItem key={4} value={45}>45˚</MenuItem>
                    <MenuItem key={5} value={30}>30˚</MenuItem>
                    <MenuItem key={6} value={15}>15˚</MenuItem>
                    <MenuItem key={7} value={0}>0˚</MenuItem>
                  </Select>
                </div>
              </div>

              <Button onClick={() => this.setState({ modalConfirm: true })}
                variant="contained"
                disabled={evalAngle === 'select'}
                style={theme.button.primary}
                className="button">
                Manual Evaluation
              </Button>

            </footer>
          </Portal>
        }

        {
          isVisible && modalConfirm &&
          <Portal container={document.body}>
            <div className="modal confirm" style={{ backgroundColor: theme.palette.primary.fade }}>
              <CoreEvaluation
                directions={directions}
                evalAngle={evalAngle}
                currentLocation={currentLocation}
                cancel={this.props.cancel}
                submitEvaluation={this.submitEvaluation}
                afterEvaluation={this.afterEvaluation}
              />
            </div>
          </Portal>
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(CoreEvaluationLayout)
