import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../styles/login.scss'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

const styles = theme => (null)

class Login extends Component {
  render () {
    let url = `${process.env.REACT_APP_LOGIN_URL}user/login?destination=${window.location}`
    return (
      <div className="page login">
        <img className="brand" alt="AllCore360˚" src={require('../img/ac-logo-full.png')}/>
        <footer>
          <Button variant="contained" style={this.props.theme.button.primary} className="button" href={url}>Login</Button>
        </footer>
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect())(Login)
