import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import sanitizeHtml from 'sanitize-html'
import nl2br from 'nl2br'
import classNames from 'classnames'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'

import '../styles/index.scss'

import { getAnnouncements, updateUserAnnouncementLog } from '../actions'

const styles = theme => (null)

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    announcements: state.announcements
  }
}

const mapDispatchToProps = {
  getAnnouncements,
  updateUserAnnouncementLog
}

class Announcement extends React.Component {
  render () {
    return (
      <div className={'announcement'}>
        <header>{this.props.title}</header>
        <div className="description" dangerouslySetInnerHTML={{ __html: this.props.content }}/>
        <footer>
          <Button style={{ fontWeight: 'bold' }} color="secondary" size="small" onClick={this.props.dismiss}>Got it</Button>
        </footer>
      </div>
    )
  }
}

class AnnouncementLayout extends React.Component {
  state = {
    activeAnnouncement: 0,
    announcementsVisible: false
  }

  async componentDidMount () {
    await this.props.getAnnouncements()
    // See if there is an unviewed announcement
    if (this.props.announcements.find(announcement => announcement.viewedAt.length < 1)) {
      this.setState({ announcementsVisible: true })
    }
  }

  onSwipe = index => {
    // Mark the announcement as viewed when we swipe or click "Got it"
    // @todo the passed index doesn't necessarily match the activeAnnouncement, so userAnnouncementLog
    // could be created for the wrong announcement. Handle the userAnnouncementLog separately from
    // the onSwipe.
    this.props.updateUserAnnouncementLog(this.props.announcements[this.state.activeAnnouncement]._id)

    if (index === this.props.announcements.length) {
      this.setState({ announcementsVisible: false })
      setTimeout(() => {
        this.setState({ activeAnnouncement: 0 })
      }, 800)
    }
    else {
      this.setState({ activeAnnouncement: index })
    }
  }

  render () {
    const { theme, announcements } = this.props

    return (
      <div>
        {
          announcements && announcements.length &&
          <button onClick={() => {
            this.setState({ announcementsVisible: true })
          }} className="announcements">
            <span className="icon bell">
              <span style={{ backgroundColor: theme.palette.secondary.main }} className="count">
                {announcements.length}
              </span>
            </span>
          </button>
        }

        {
          announcements && announcements.length &&
          <div className={this.state.announcementsVisible ? 'announcementsModal active' : 'announcementsModal'}>
            <div onClick={() => {
              this.setState({ announcementsVisible: false })
            }} className="dismiss"/>
            <ol className="indicators">
              {
                announcements.length > 1 && announcements.map((announcement, index) => {
                  return (
                    <li
                      key={announcement._id}
                      onClick={() => {
                        this.onSwipe(index)
                      }}
                      className={classNames(this.state.activeAnnouncement === index && 'active')}
                    />
                  )
                })
              }
            </ol>

            <SwipeableViews
              index={this.state.activeAnnouncement}
              onChangeIndex={this.onSwipe}>
              {
                this.props.announcements.map((announcement, index) => {
                  return (
                    <Announcement
                      key={announcement._id}
                      content={nl2br(sanitizeHtml(announcement.description))}
                      title={announcement.title}
                      dismiss={() => {
                        this.onSwipe(index + 1)
                      }}
                      currentIndex={this.state.activeAnnouncement}
                      index={index}
                    />
                  )
                })
              }
            </SwipeableViews>
          </div>
        }
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(AnnouncementLayout)
