var validateRideConfig = function validateRideConfig(rideConfig) {
  // validate sets
  var ajv = require('ajv')();

  var schema = require('../schemas/rideConfig');

  return ajv.validate(schema, rideConfig);
};

var calculateCoreScore = function calculateCoreScore(rideConfig) {
  var spinFactor = 20;
  var setChangeFactor = 5;
  var totalSpins = rideConfig.sets.map(function (set) {
    return set.spins;
  }).reduce(function (acc, val) {
    return acc + val;
  });
  var averageAngle = rideConfig.sets.map(function (set) {
    return set.degrees * set.spins / totalSpins;
  }).reduce(function (acc, val) {
    return acc + val;
  });
  var setChanges = rideConfig.sets.length;
  totalSpins = Math.min(totalSpins, 10);
  setChanges = Math.min(setChanges, 10);
  var angleFactor = (90 - averageAngle) / 90 * 450;
  var coreScore = Math.round(Math.min(totalSpins * spinFactor, 200) + Math.min(angleFactor, 450) + Math.min(setChanges * setChangeFactor, 50));
  rideConfig.coreScore = coreScore;
  return coreScore;
};

var calculateCoreEvaluationScore = function calculateCoreEvaluationScore(coreEvaluation) {
  var durations = coreEvaluation.durations;
  var strengthFactor = 0;

  switch (coreEvaluation.angle) {
    case 0:
      {
        strengthFactor = 9;
        break;
      }

    case 15:
      {
        strengthFactor = 7;
        break;
      }

    case 30:
      {
        strengthFactor = 5;
        break;
      }

    case 45:
      {
        strengthFactor = 4;
        break;
      }

    case 60:
      {
        strengthFactor = 2;
        break;
      }

    case 75:
      {
        strengthFactor = 1.5;
        break;
      }

    case 80:
      {
        strengthFactor = 1;
        break;
      }

    default:
      {
        strengthFactor = 1;
        break;
      }
  }

  var sagitalBalance = Math.min(durations.left, durations.right) / Math.max(durations.left, durations.right);
  var coronalBalance = Math.min(durations.forward, durations.backward) / Math.max(durations.forward, durations.backward);
  var enduranceFactor = Object.values(durations).reduce(function (acc, val) {
    return acc + val;
  }, 0);
  var balanceFactor = (sagitalBalance + coronalBalance) / 2;
  var evaluationConstant = 43.2;
  var score = Number(strengthFactor * enduranceFactor * balanceFactor / evaluationConstant).toFixed(1);
  coreEvaluation.score = score;
  return score;
};

module.exports = {
  validateRideConfig: validateRideConfig,
  calculateCoreScore: calculateCoreScore,
  calculateCoreEvaluationScore: calculateCoreEvaluationScore
};