import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

// Components
import PatientLayout from './PatientLayout'
import PatientSingleLayout from './PatientSingleLayout'
import SettingsLayout from './SettingsLayout'
import ReportsLayout from './ReportsLayout'
import ReportsRideDataLayout from './ReportsRideDataLayout'
import LeaderboardLayout from './LeaderboardLayout'

import '../styles/index.scss'
import '../styles/navigation.scss'
import 'common/styles/index.scss'
import { authenticate } from 'common/actions'
import TabBar from './TabBar'
import Login from './Login'
import SnackBar from './SnackBar'

import AnnouncementLayout from './AnnouncementLayout'

const mapStateToProps = ({ status: { isLoading }, currentUser, announcements }) => {
  return {
    isLoading,
    isAuthenticated: !!currentUser._id,
    isTherapist: currentUser.roles && (currentUser.roles.includes('therapist') || currentUser.roles.includes('administrator')),
    announcements,
  }
}
const mapDispatchToProps = {
  authenticate,
}

class App extends Component {
  async componentDidMount () {
    await this.props.authenticate()
  }

  onSwipe = index => {
    if (index === this.props.announcements.length) {
      this.setState({ announcementsVisible: false })
      setTimeout(() => {
        this.setState({ activeAnnouncements: 0 })
      }, 800)
    }
    else {
      this.setState({ activeAnnouncements: index })
    }
  }

  render () {
    // Show nothing if we're still loading
    if (this.props.isLoading) {
      return null
    }

    // Show the login component if we aren't authenticated
    if (!this.props.isAuthenticated) {
      return (<Login />)
    }

    return (
      <div className="App">
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <Route exact path="/" component={this.props.isTherapist ? PatientLayout : PatientSingleLayout} />
          <Route exact path="/user" component={PatientLayout}/>
          <Route exact path="/user/:id" component={PatientSingleLayout}/>
          <Route exact path="/settings" component={SettingsLayout}/>
          <Route exact path="/reports" component={ReportsLayout}/>
          <Route exact path="/reports/rides" component={ReportsRideDataLayout}/>
          <Route exact path="/reports/leaderboards" component={LeaderboardLayout}/>
          { this.props.isTherapist && <TabBar /> }
          <SnackBar />
          { this.props.announcements.length > 0 && <AnnouncementLayout /> }
        </Router>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
