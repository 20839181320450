import ReactGA from 'react-ga';
var currentPage = '';
export function createGoogleAnalyticsMiddleware(gaID) {
  ReactGA.initialize(gaID);
  return function (store) {
    return function (next) {
      return function (action) {
        if (action.type === '@@router/LOCATION_CHANGE') {
          var _action$payload = action.payload,
              pathname = _action$payload.pathname,
              search = _action$payload.search;
          var nextPage = "".concat(pathname).concat(search);

          if (currentPage !== nextPage) {
            currentPage = nextPage;
            ReactGA.pageview(nextPage);
          }
        }

        return next(action);
      };
    };
  };
}