import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

// Material UI components
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

// Components
import TotalRidesContainer from './TotalRidesContainer'

// Actions
import { getReport } from '../actions'

// Styles
import '../styles/patientProfile.scss'

// Utilities
import moment from 'moment'

const mapStateToProps = ({ currentUser, reports }, ownProps) => {
  let reportKey
  let reportOptions = {}

  let { group, location, dateRange } = ownProps

  // Generate the key for this report and set the options for the query.
  if (group === 'all') {
    reportKey = 'group-all'
  }
  else {
    reportKey = `group-${group}`
    reportOptions = { ...reportOptions, group }
    if (location) {
      if (location === 'all') {
        reportKey = `${reportKey}-location-all`
      }
      else {
        reportKey = `${reportKey}-location-${location}`
        reportOptions = { ...reportOptions, location }
      }
    }
  }

  let dateFrom, dateTo
  switch (dateRange) {
    case 'dateRange': {
      dateFrom = moment(ownProps.dateFrom)
      dateTo = moment(ownProps.dateTo)
      break
    }
    case 'thisYear': {
      dateFrom = moment().startOf('year')
      dateTo = moment().endOf('year')
      break
    }
    case 'thisQuarter': {
      dateFrom = moment().startOf('quarter')
      dateTo = moment().endOf('quarter')
      break
    }
    case 'thisMonth':
    default: {
      dateFrom = moment().startOf('month')
      dateTo = moment().endOf('month')
      break
    }
  }

  reportKey = `${reportKey}-${dateRange}-${dateFrom.format('YYYY-MM-DD')}-${dateTo.format('YYYY-MM-DD')}`
  reportOptions = {
    ...reportOptions,
    dateFrom,
    dateTo,
  }

  let loading = true
  let totalCost = 0
  let numRides = 0
  let report = reports.data[reportKey]
  if (report) {
    // Calculate the totals for all months
    report.forEach(monthReport => {
      totalCost += monthReport.totalCost
      numRides += monthReport.numRides
    })
    loading = false
  }

  return {
    currentUser,
    reportKey,
    reportOptions,
    report,
    dateRange,
    totalCost,
    numRides,
    loading,
  }
}
const mapDispatchToProps = { getReport }

const styles = theme => (null)

class ReportsRideData extends React.Component {
  // @todo how are these calculated?
  state = {
    costPerSpin: 0,
    numFreeSpins: 0
  }

  componentDidMount () {
    // Get the report
    this.fetchReports()
  }

  componentDidUpdate (prevProps) {
    // If the key changed, get the new report.
    if (this.props.reportKey !== prevProps.reportKey) {
      this.fetchReports()
    }
  }

  fetchReports = () => {
    this.props.getReport(this.props.reportKey, this.props.reportOptions)

    let groupId = this.props.group
    let locationId = this.props.location

    let currentGroup = this.props.currentUser.groups.find((group) => {
      return group._id === groupId
    })

    let currentLocation = currentGroup && currentGroup.locations.find((location) => {
      return location._id === locationId
    })

    if (currentLocation) {
      this.setState({ costPerSpin: currentLocation.costPerSpin, numFreeSpins: currentLocation.numFreeSpins })
    }
  }

  render () {
    return (
      <div>
        {
          this.state.costPerSpin > 0 && this.state.numFreeSpins > 0 &&
          <div className="flexRow locationInfo">
            <span>Monthly Free Rides</span>
            <strong>{this.state.numFreeSpins}</strong>
            <span>Cost Per Ride</span>
            <strong><span>$</span>{this.state.costPerSpin}</strong>
          </div>
        }

        <TotalRidesContainer
          numRides={this.props.numRides}
          totalCost={this.props.totalCost}
          dateRange={this.props.dateRange}
          dateFrom={this.props.dateFrom}
          dateTo={this.props.dateTo}
          loading={this.props.loading}
        />

        {
          !this.props.loading && this.props.report && this.props.report.length > 1 &&
            <Table className="monthlyTotals">
              <TableBody>
                {
                  this.props.report.map(monthReport => (
                    <TableRow key={monthReport._id}>
                      <TableCell className="rides">
                        <span>Total Rides for</span>
                        <strong>{moment(monthReport._id, 'YYYY-MM').format('MMMM YYYY')}</strong>
                      </TableCell>
                      <TableCell className="rideTotal">
                        <strong>{monthReport.numRides}</strong>
                      </TableCell>
                      <TableCell>
                        <span>Total Owed</span>
                      </TableCell>
                      <TableCell className="owedTotal">
                        <span>$</span>
                        <strong>{monthReport.totalCost ? monthReport.totalCost : '0'}</strong>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
        }
      </div>
    )
  }
}

ReportsRideData.propTypes = {
  group: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  dateRange: PropTypes.string.isRequired,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(ReportsRideData)
