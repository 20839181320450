import React, { Component } from 'react'

class InputNumeric extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  handleChange = (event) => {
    this.setState({ value: Number(event.target.value) })
  }

  render () {
    return (
      <input
        disabled={this.props.disabled}
        size={2}
        type="number"
        pattern="[0-9]*"
        style={{ color: this.props.color }}
        maxLength={2}
        onChange={this.handleChange}
        onBlur={(event) => this.props.onChange(this.state.value)}
        value={this.state.value} />
    )
  }
}

export default InputNumeric
